<template>
  <div class="flex items-center gap-2 overflow-x-auto py-4">
    <template v-for="[groupKey, chipsVal] in Object.entries(chips)">
      <AuctionListFilterChip
        v-for="chip in chipsVal"
        :key="chip"
        :group-key="(groupKey as GroupKey)"
        :value="chip"
        @click="onChipClick(groupKey as GroupKey, chip)"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAuctionStore } from '@autobid/nuxt-pinia-store/store/useAuctionStore'
import { removeReference } from '@autobid/strapi-integration/utils/helpers'
import { useDesignationDisplayValue } from '@autobid/ui/composables/useDesignationDisplayValue'
import { formatDateToInputFormat } from '@autobid/ui/utils/date/formatDateToInputFormat'
import type { ParsedFilterData } from '@autobid/strapi-integration/typescript/FilterData'
import type { GroupKey } from './AuctionListFilterChip.vue'
import AuctionListFilterChip from './AuctionListFilterChip.vue'

const auctionStoreKey = inject('auctionStoreKey')
const { filter } = storeToRefs(useAuctionStore(auctionStoreKey))

const { t } = useI18n()

const calcDesignationDisplayValue = useDesignationDisplayValue()

const filterData = inject('filterData') as Ref<ParsedFilterData>

const chips = computed(() => {
  const getStartDateRange = () => {
    const isJustSingleSelected =
      Object.values(filter.value.startDateRange).filter(Boolean).length === 1

    const { dateFrom, dateTo } = filter.value.startDateRange

    if (isJustSingleSelected) {
      return [dateFrom.length ? dateFrom : dateTo]
    }

    const temp =
      dateTo.length && dateFrom.length ? [dateFrom, dateTo] : undefined

    if (!temp) {
      return undefined
    }
    if (temp[0] === temp[1]) {
      const isToday = formatDateToInputFormat(new Date()) === temp[0]
      return [isToday ? t('auction-list.today') : temp[0]]
    }
    return [`${temp[0]} - ${temp[1]}`]
  }

  const {
    designations,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    startDateRange: _startDateRange,
    ...restFilterValue
  } = filter.value

  return {
    designations: calcDesignationDisplayValue(designations),
    startDateRange: getStartDateRange(),
    ...restFilterValue
  }
})

const onChipClick = (groupKey: GroupKey, valueToRemove: unknown) => {
  if (groupKey === 'startDateRange') {
    filter.value.startDateRange.dateFrom = filter.value.startDateRange.dateTo =
      ''
    return
  }

  const getValueToRemove = () => {
    if (groupKey !== 'designations') {
      return valueToRemove
    }

    const { id } =
      filterData.value.designations.find(
        (desItem) => desItem.label === valueToRemove
      ) ?? {}

    return id
  }

  const convertedValueToRemove = getValueToRemove()

  filter.value[groupKey] = (
    removeReference(filter.value[groupKey]) ?? []
  ).filter((item) => item !== convertedValueToRemove)
}
</script>
