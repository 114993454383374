import type { ParsedFilterData } from '@autobid/strapi-integration/typescript/FilterData'

export const useDesignationDisplayValue = () => {
  const filterData = inject('filterData') as Ref<ParsedFilterData>

  return (des: unknown) => {
    if (!Array.isArray(des)) {
      return
    }
    if (!des.length) {
      return
    }
    return des.map(
      (item) =>
        filterData.value.designations.find(
          (designation) => designation.id === item
        ).label
    )
  }
}
